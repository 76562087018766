
  .contact-us-holder{
    width: 100%;
    min-height: 60vh;
    padding-left: 10% ;
  
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  /*.contact-us-holder{*/
  /*  width:  ;*/
  /*}*/
  .line-form{
    top: 50%;
    transform: translate(0,-50%);
    left: 0;
    width: 30px;
    height: 2px;
    margin-right: 10px;
    background-color: black;
  
  }
  .contact-form-holder{
    width: 98%;
    
      display: inline-flex;
      align-items: center;
      padding-top: 5%;
      height: 90vh;
    }
  .title-us,.description-who-we-are-from,.u-contact-form h3{
    padding: 0;
  }
  .u-contact-h3-from{
    font-family: Bold, sans-serif;
    font-size: 1.17em;
    letter-spacing: 0.54em;
    line-height: 2;
    max-width: 526px;
  }
  .contact-us-50-holder{
    width: 100%;
  }
  .google-map{
    width: 20%;
  }
  
  .u-contact-form{
      padding: 25px;
      width: 95%;
  }
  
 
  
  
  
  .contact-form1{
    height:60vh;
    width: 100%;
    padding-top: 5%;
  
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .displayNone{
    display: none;
  }
  
  /*input:focus + label{*/
  /*    opacity: 0;*/
  /*  }*/
  
  
  
  form{
    width: 90%;
    max-width: 600px;
  }
  .input-group{
    margin-bottom: 30px;
    position: relative;
  }
  input,textarea,select{
    width: 100%;
    padding: 10px;
    outline: 0;
    border: 1px solid #d7d7d7 ;
    background-color: #fff;
    font-size: 15px;
  }
  label{
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    color: #666;
    cursor: text;
    padding: 4%;
  }
  form button{
    padding: 10px 0;
    color: #666;
    outline: none;
    background: transparent;
    border: 1px solid #d7d7d7;
    width: 100%;
    cursor: pointer;
  
    border-radius: 0 !important;
  }
  
  .form-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  }
  .form-row .input-group{
    flex-basis: 48%;
  }
  
  
  
  .google-map2{
    display: flex;
    justify-content: center !important;
    width: 100%;
    align-items: center !important;
  }
  
  
  @media (max-width: 750px) {
  .contact-form-holder{
    flex-direction: column;
    height: 100%;

  }
  .google-map{
    display: none;
  }
    .google-map2{
      display: flex;
    }
  .contact-form-holder,.contact-us-holder{
    width: 100%;
    padding: 10% 0 0 0;
    justify-content: center;
    align-items: center;
  
  }
  .contact-us-50-holder{
    width: 95%;
  }
    .u-contact-form{
      width: 100%;

    }
  
  
  }
  
  
  @media (min-width: 751px) {
    .google-map2{
      display: none;
    }
    .google-map{
      display: unset;
    }
  
  }
  
  
  .responsive-iframe {
    width: 100%;
    height: 100%;
  }
  
  
  
  
  
  
  
  
  
  .error-message br+br {
    margin-top: 25px;
  }
  .error-message {
    /* display: none; */
    background: rgba(255, 255, 255, 0.08);
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .sent-message {
    /* display: none; */
    background: rgba(255, 255, 255, 0.08);
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .loading {
    /* display: none; */
    background: rgba(255, 255, 255, 0.08);
    text-align: center;
    padding: 15px;
  }
  
  .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  