*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  font-size: unset;
}

html,
body {
  overflow: auto;
  /* Change from "hidden" to "auto" */
  overflow-x: hidden;
}

body {
  min-height: 100vh;
}


.size-banner {
  height: auto;

}

.image-banner-np {
  height: 80vh;
  width: 98%;
  /* background-image: url(/public/img/images2/backImage.jpeg); */
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  & h2 {
    font-size: 90px;
    font-family: Bold, sans-serif;
    text-transform: uppercase;
  }
}

.paddingAll {
  padding: 1%;
}

.alignTextCentre {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;



}

.more-about-us {
  height: auto;

  margin-top: 10%;
  align-content: center !important;
  text-align: center !important;
  justify-content: center !important;
}

.u-service {
  width: 100%;

  display: flex;
  text-align: center !important;
  justify-content: center !important;
}

.services2 {
  padding-top: 54px;
  font-family: Bold, sans-serif;
  font-size: 1.17em;
  letter-spacing: 0.54em;
  line-height: 2;
  max-width: 626px;
  padding-bottom: 40px;
}

.more-about-us>.title-us {
  padding-left: 0;
}

.max-width660 {
  max-width: 660px;

}

.u-service>.description-who-we-are {
  font-family: Book, sans-serif;
}


.testimonial-banner {
  height: auto;
}

.testimonial-grey {
  min-height: 70vh;
  margin-bottom: 20%;
  display: flex;
  flex-direction: column;
  /*height: auto;*/
}

.testimonial-slider {
  display: flex;
}

.testimonial {

  /* width: 50%; */
  /*display: flex;*/
  & p {
    padding-left: 18px;
  }
}

/*.quote{*/
/*  background-image: url("/img/comma.png");*/
/*  background-repeat: no-repeat;*/
/*}*/

.testimonial-heading {
  max-width: 400px;
  font-size: 16px;
  padding-left: 42px;
}

.width90 {
  width: 90%;
}











.borderLine {
  border: black solid;
}


.image-recent-work {

  width: 100%;
  border-radius: 50%;
  height: auto;

}

.image-recent-work-container {
  padding: 90px 72px 52px;
}


.display-cel:nth-child(1),
.display-cel:nth-child(2) {
  margin-right: -5px;

}

.display-cel {
  display: inline-flex;

  width: 33.37%;
}

.margin0 {
  margin: 0;
  padding: 0;

}

.row {
  width: 100%;
}

.recent-work-info .title-us {
  display: flex;
  flex-direction: column;

  & .who-we-are {
    padding: 5% 0 4%;
  }

  & h6 {
    padding-bottom: 5%;
  }
}



.recent-work-h2 {
  line-height: 1.5;
  font-family: "Montserrat", sans-serif;
  color: #333;
  font-size: 15px;

  padding-left: 10%;
  width: 100%;
  padding-bottom: 2%;
}

.display-flex {
  display: inline-flex;
}


.row .hyper-link {
  position: inherit;
  display: flex;
  flex-direction: row-reverse;

  padding-right: 4%;
}




@media (max-width: 750px) {
  .display-cel:nth-child(3) {
    display: inline-flex;
  }

  .display-cel {
    width: 100%;

  }
  .right50 {
    width: 98%;
  }
  .description-who-we-are {
    width: 90%;
  }

  .heading {

    display: flex;
    text-align: center;
    justify-content: center;
  }

  .testimonial-slider {

    display: flex !important;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  .testimonial {
    width: 100%;
    padding: 2%;
  }

  .image-banner-np {
    height: 50vh;
  }

  .image-recent-work-container {
    padding: 7% 20% 1%;
  }

}



@media (max-width: 993px) {
  .services2 h2 {
    font-size: 1.17em !important;
  }

  .testimonial-heading {
    padding-top: 10%;

  }
}


@media (max-width: 1101px) and (min-width: 751px) {
  .display-cel:nth-child(3) {
    display: none;
  }

  .display-cel {
    width: 50%;
  }
}





.video-container,
.adVideo,
.adVideoTag {
  width: 100%;
  /* min-height: auto; */
  padding-top: 2%;
  height: 100%;

}

@media (max-width: 992px) {

  .video-container,
  .adVideo,
  .adVideoTag {
    width: 100%;
    
    padding-top: 4%;
    height: 100%;

  }
}

@media (max-width: 660px) {

  .video-container,
  .adVideo,
  .adVideoTag {
    width: 100%;
    
    padding-top: 7%;
    height: 100%;

  }
}

/*
  .adVideo {
    position: relative;
  }
  
  .controls {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    /* border-color: 1px #ebebeb; */
/*}
  
  .controls button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .controls button:hover {
    text-decoration: underline;
  }
  
  .controls input {
    width: 100px;
    margin-right: 10px;
  }
  
  .controls input::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .controls input::-webkit-slider-runnable-track {
    appearance: none;
    height: 5px;
    background-color: #fff;
    border-radius: 5px;
  }
   */