
#modeIcon {
    display: inline-block;
    width: 30px; /* Adjust width and height as needed */
    height: 30px;
    transition: transform 1.5s ease;
    font-size: 1.5rem;
    
  }
  
  .dark-mode #modeIcon {
    transform: translateY(5px);
  }
  .dark-mode .line{
   background-color: #F0F0F0;
  }
  .dark-mode .line-form{
    background-color: #F0F0F0;
   }

  .dark-mode header{
    background-color: #373737;
  }
  .dark-mode footer{
    background-color: #373737;
  }
  .dark-mode ::placeholder{
    color: #ffffff;
  }
  #toggleDarkModeButton{
    background-color: transparent !important;
    padding: 0;
    margin: 0;
    border: none;
    
  }
  
  /*.dark-mode .fa-brands{*/
  /*  background-color: #F0F0F0;*/
  /*}*/
  .sun-icon {
    fill: #FFD700;
    
  }
  
  .moon-icon {
    fill: #eaeaea;
  }
  header {
    position: absolute;
  }
  .dark-mode-toggle {
    position: absolute;
    top: 25%;
    right: 1%;
  }
  
  /* styles to define light and dark mode */
  
  /*body.light-mode {*/
  /*  background-color: #fff; !* Set your light mode background color *!*/
  /*  color: #000; !* Set your light mode text color *!*/
  /*}*/
  
  /*body.dark-mode {*/
  /*  background-color: #000; !* Set your dark mode background color *!*/
  /*  color: #fff; !* Set your dark mode text color *!*/
  /*}*/
  
  body {
    transition: background-color 1s ease, color 1s ease; 
  }
  
  body.light-mode {
    background-color: #F0F0F0;
    color: #121212;
  }
  
  body.dark-mode {
    background-color: #282828;
    color: #F0F0F0;
  }
  body.light-mode p {
    color: #121212; 
  }
  body.dark-mode p  {
    color: #F0F0F0; 
  }
  
  
  body.dark-mode .paragraph-banner {
    color: #cccccc;
  }
  body.dark-mode  h4 {
    color: #cccccc;
  }
  body.dark-mode  h2 {
    color: #cccccc;
  }
  
  
  
  body.dark-mode .testimonial-grey {
    background-color: #1a1a1a;
    color: #F0F0F0;
  }
  
  body.dark-mode .testimonial-written h2 {
    color: #F0F0F0;
  }
  
  
  body.dark-mode .copy-right {
    color: #cccccc;
  }
  
  body.dark-mode .testimonials-logos {
    background-color: #1a1a1a;
  }
  
  body.dark-mode .footer-ul li a {
    color: #F0F0F0;
  }
  
  body.dark-mode .formFooter input {
    color: #F0F0F0;
  }
  
  
  



/* Form and Contact Styles in Dark Mode */
.dark-mode form {
  /* background-color: #1a1a1a; */
  color: #F0F0F0;
  /* border: 1px solid #333; */
}

.dark-mode .input-group {
  border: 1px solid #333;
}

.dark-mode label {
  color: #666;
}

.dark-mode input,
.dark-mode textarea,
.dark-mode select {
  background-color: #333;
  color: #F0F0F0;
  border: 1px solid #555;
}

.dark-mode form button {
  color: #F0F0F0;
  /* border: 1px solid #555; */
}

/* Error, Sent, and Loading Messages in Dark Mode */

.dark-mode  .sent-message,
.dark-mode  .loading {
  background: rgba(255, 255, 255, 0.08);
  background: #7dfe72;
  color: #303030;
}
.dark-mode  .error-message{
  background: rgba(255, 255, 255, 0.08);
  background: #fe7272;
  color: #303030;
}

.dark-mode  .loading:before {
  border: 3px solid #18d26e;
  border-top-color: #eee;
}

/* Dark Mode for Contact Form Holder */
.dark-mode  .contact-form-holder {
  /* background-color: #1a1a1a; */
  color: #F0F0F0;
}

/* Dark Mode for Google Map Holder */
.dark-mode  .google-map {
  background-color: #1a1a1a;
}

/* Dark Mode for Contact Us Holder */
.dark-mode  .contact-us-holder {
  /* background-color: #282828; */
  color: #F0F0F0;
}



.sheet{
  background-color: #fff
}

.dark-mode .sheet{
  background-color: #555;
}

.dark-mode .sheetCloseButton{
  color: #ffffff !important;
  background-color: #3c3c3c !important;
}

.sheetCloseButton{
  color: #5e5e5e !important;
  background-color: #f8f8f8 !important;
}

.input-group {
  border: 1px solid #adadad;
}

