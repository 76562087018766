@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  z-index: 1;
}

html {
  width: 100%;
}

html,
body {
  overflow: auto;
  /* Changed from "hidden" to "auto" */
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  z-index: 0;
}

.title-us,
.description-who-we-are,
.u-contact h3 {
  padding-left: 26px;
}

.svg-blob {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 0 !important;
}

.img-bg {
  position: relative;
}

.nav__links {
  list-style: none;
}

.nav__links_wildcard {
  list-style: none;
}

header {
  position: sticky;
  position: absolute;
  width: 100%;
  /*position: relative;*/
  top: 0;
  z-index: 20 !important;
  background-color: #24252a;
}

.positionRelativeZindex5 {
  position: relative;
  z-index: 5;
}

.nonTouch {
  user-select: none;
  pointer-events: none;
}

.logo.selected {
  filter: brightness(100%);
  color: #b42a76;
  background-color: #b42a76;
}

.front-h3 {
  font-size: 25vw;

  padding-top: 8%;

  /*background-image: url(/public/img/test-img.jpeg);*/
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-family: "Montserrat", sans-serif;
  animation: imageTransition 10s infinite alternate;
  /* Adjust timing as needed */
  margin: 0;
  /*padding: 0;*/
  pointer-events: none;
  /* Disable pointer interactions */
  user-select: none;
}

h1,
h2,
h3,
h4,
h6,
h6,
p {
  font-family: "Montserrat", sans-serif;
}

@keyframes imageTransition {
  0% {
    background-image: url(/public/img/recuringImage/image1.webp);
  }

  25% {
    background-image: url(/public/img/recuringImage/image2.webp);
  }

  50% {
    background-image: url(/public/img/recuringImage/image3.webp);
  }

  75% {
    background-image: url(/public/img/recuringImage/image4.webp);
  }

  100% {
    background-image: url(/public/img/recuringImage/image5.webp);
  }
}

.back-banner-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #ffffff; */
  height: 100vh;
  /* Set an appropriate height */
}

.back-banner-2 {
  width: 80%;
  height: 100%;
  min-width: 550px;
  /* background-color: #ffffff; */
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.image-banner {
  position: relative;
  text-align: center;
  /* color: white; */
}

.data-banner {
  padding: -20vh;
  text-align: center;
}

.heading-banner {
  letter-spacing: 0.54em;
  font-family: "Montserrat", sans-serif;
}

/*.heading-banner h3 {*/
/*  font-size: 2rem;*/
/*  color: white;*/
/*}*/
.paragraph-banner h4 {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

.heading-banner {
  margin: 10px;
}

.paragraph-banner {
  margin: auto 30%;
  /*padding:  10%;*/
}

/*Social Icon Banner*/
.social-icon-banner {
  display: flex;
  /* background-color: #ffffff; */
  width: 100%;
  padding-top: 2.5%;
  float: left;
}

.social-icon-banner-i {
  cursor: pointer;
  text-decoration: none;
  text-transform: inherit;
  color: inherit;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 10px;
  font-size: 20px;
}

/*Hamburger*/
.menu-icon div {
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  /*display: none;*/
}

nav.active {
  right: 0;
}

nav.hide {
  right: -100%;
}

/*Banner with Image*/
.next-banner-1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.next-banner-2 {
  justify-content: center;
  align-content: center;
  display: flex;
  width: 80%;
  height: 80%;
}

.next-banner-3 {
  width: 98%;
  display: flex;
}

.left-banner {
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-solid-color {
  width: 50%;
  height: 60vh;
  /*border-style: solid;*/
  /*border-width: 0 0 380px 420px;*/
  /*border-color: transparent transparent #edab20 transparent;*/
  background-color: #f5b121;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-change-area {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10vh;
  position: relative;
  /* Add position property */
}

.text-change-area-h3 {
  text-transform: uppercase;
  font-family: "Arial Black", sans-serif;
  font-size: 60px;
  letter-spacing: 0.14em;
  text-align: center;
  margin: 10px 0;
}

.DisplayNone {
  display: none !important;
}

.right-banner {
  width: 50%;
  /*background-color: rgba(97, 86, 249, 0.75);*/
}

.little-lower {
  padding-top: 30px;
  /*background-color: #39ac31;*/
  height: 100px;
}

.title-us {
  display: inline-flex;
  /*flex-direction: row-reverse;*/
  justify-content: space-between;
  padding-left: 26px !important;
  letter-spacing: 0.2em;
  /*background-color: #cccccc;*/
}

.who-we-are {
  font-size: 12px;
  margin-top: -8px;
  /*font-weight: lighter;*/
  text-transform: uppercase;
  /*background-color: #39ac31;*/
  font-family: "Regular", system-ui;
}

.line {
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 30px;
  height: 2px;
  margin-right: 10px;
  background-color: black;
}

h4 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
  display: inline;
  text-transform: inherit;

  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.description-who-we-are {
  font-family: "Book", system-ui;
  font-size: 15px;
  color: #222;
  line-height: 1.8;
  padding-top: 38px;
  padding-bottom: 54px;
  padding-left: 20px;
}

.AnimateMe {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/*Slider Our Clients*/
.container {
  padding-top: 15vh;
  padding-bottom: 20vh;
  /* height: 80vh; */
}

.customer-logos-h2 {
  margin-bottom: 5%;
}

.text-center {
  text-align: center;
  padding: 10px 0 20px;
  font-family: "Montserrat", sans-serif;
}

.slick-slide {
  margin: 0 10px;
}

.slick-slide img {
  width: 60%;
}

.ih1 {
  padding-top: 15%;
}

.ih2,
.ih3 {
  padding-top: 13%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin-right: -12%;
  padding: 0;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide img {
  display: block;
}

.slick-initialized .slick-slide {
  display: block;
}

.slider {
  text-align: center;
}

.copy {
  padding-top: 250px;
}

.testimonial-banner {
  padding-top: 10%;
  font-family: "Montserrat", sans-serif;

  width: 100%;
  height: 100%;

  justify-content: center;
  display: flex;
}

.testimonial-grey {
  background-color: #edf1f5;
  width: 70%;
  min-height: 70vh;
  margin-bottom: 20vh;
  transform: translate(0, 0);

  justify-content: center;
  display: flex;
  align-items: center;
}

.display-row {
  padding: 10% 0 10%;
  display: table-row;
  width: 90%;
  height: auto;
  /*background-color: #39ac31;*/
}

.display-cell {
  display: table-cell;
}

.display-last {
  display: inline-flex;
}

.testimonials-logos {
  width: 50%;
  height: auto;
  max-height: 40vh;
  text-align: center;
  justify-content: center;
  /*background-color: black;*/
}

.testimonials-written {
  font-family: "Montserrat", sans-serif;
  width: 50%;
  height: auto;
  /*background-color: #d77777;*/
}

.testimonial-heading {
  /*position: relative;*/
  /*font-family: 'Bold';*/
  font-size: 25px;
  letter-spacing: 0.54em;
  color: #222;
  line-height: 1.92;
  text-transform: uppercase;

  padding-bottom: 20px;

  /*margin-top: 5px;*/
  /*margin-right: 5px;*/
}

.testimonials-written h2 {
  font-size: 26px;
}

.blackAndWhiteImg {
  /* Apply a transition for smooth effect */
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  /* Apply grayscale filter initially */
  filter: grayscale(100%);
}

/* On hover or touch, remove the grayscale effect to show color */
.blackAndWhiteImg:hover,
.blackAndWhiteImg:focus {
  filter: grayscale(0%);
}

.slick-dots {
  display: inline-flex;
  text-align: center;
  /* Center the dots horizontally */
}

.slick-dots li {
  display: inline-block;
  /* Display dots inline */
  margin: 100px 5px;
  /* Add spacing between dots */
}

.slick-dots li button {
  font-size: 0;
  background: #bbc4ce;
  border: none;
  border-radius: 50%;
  width: 0;
  height: 0;
  cursor: pointer;
}

.slick-dots li.slick-active button {
  background: #b42a76;
  /* Change active dot color */
}

.floatL {
  float: left;
}

.width100 {
  width: 100%;
}

/*Footer*/
.sticky-footer {
  width: 100%;
  position: relative;
  /*top: 100%;*/
  bottom: 0;
  /*background: linear-gradient(to right, #000000, #000000);*/
  background: #24252a;
  color: #fff;
  padding: 50px 0 30px;
  /*border-top-left-radius: 125px;*/
  font-size: 13px;
  line-height: 20px;
}

.rowFooter {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.colFooter {
  flex-basis: 25%;
  padding: 10px;
}

.colFooter:nth-child(2),
.colFooter:nth-child(3) {
  flex-basis: 15%;
}

.footer-paragraph {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.logofooter {
  width: 120px;
  margin-bottom: 30px;
}

.colFooter h3 {
  width: fit-content;
  margin-bottom: 40px;
  position: relative;
}

.email-id {
  width: fit-content;
  border-bottom: 1px solid #cccccc;
  margin: 20px 0;
  cursor: pointer;
}

.footer-ul li {
  list-style: none;
  margin-bottom: 12px;
  cursor: pointer;
}

.footer-ul li a {
  list-style: none;
  color: #ffffff;
}

.formFooter {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 50px;
}

.formFooter .fa-regular {
  font-size: 18px;
  margin-right: 3%;
}

.formFooter input {
  min-width: 80%;
  max-width: 120%;
  background: transparent;
  color: #cccccc;
  border: 0;
  outline: none;
}

.formFooter button {
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.formFooter .buttonFooter .fa-solid {
  font-size: 16px;
  color: #cccccc;
}

.social-icon-footer .fa-brands {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #000;
  background: #ffffff;
  margin-right: 15px;
  cursor: pointer;
}

hr {
  width: 90%;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 20px auto;
}

.copy-right {
  text-align: center;
}

.underline-h3 {
  width: 100%;
  height: 1px;
  background: #767676;
  /*border-radius: 3px;*/
  position: absolute;
  top: 25px;
  left: 0;
  /*overflow: hidden;*/
}

/*.underline-h3 span{*/
/*  width: 15px;*/
/*  height: 100%;*/
/*  background: #fff;*/
/*  border-radius: 3px;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 10px;*/
/*  animation: moving 2s linear infinite;*/
/*}*/
/*@keyframes moving {*/
/*  0%{*/
/*    left: -20px;*/
/*  }*/
/*  100%{*/
/*    left: 100%;*/
/*  }*/
/*}*/

.testimonial-grey {
  /*1389px*/
  width: 70%;
}

.testimonials-logos {
  display: grid;
}

.heightAuto {
  height: auto;
}

/*.testimonial-grey{*/
/*  height: 80vh;*/
/*}*/

@media (max-width: 1200px) {
  .testimonial-heading {
    max-width: 70%;
  }

  .tbl-col {
    width: 95% !important;
  }

  .left50 {
    width: 50% !important;
  }
}

@media (min-width: 992px) {
  .testimonials-logos {
    width: 50%;
  }

  li,
  a,
  button {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #edf0f1;
    text-decoration: none;
  }

  header {
    display: flex;
    justify-content: space-between;
    /*justify-content: flex-end; !*flex everything to right*!*/
    align-items: center;
    padding: 30px 10%;
  }

  .logo {
    cursor: pointer;

    z-index: 20;
    /*margin-right: auto;!*To put logo at left*!*/
  }

  .nav__links li {
    transition: all 0.3s ease 0s;
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
  }

  .nav__links li a {
    transition: all 0.3s ease;
  }

  .nav__links a:hover {
    text-decoration-color: #3c3c3c;
    color: #8c9193;
  }

  button,
  #button-a {
    /*margin-left: 20px;*/
    /*margin-left: 10px;*/
    padding: 9px 25px;
    background-color: #3c3c3c;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
  }

  button:hover {
    background-color: #424141;
  }

  .menu-icon {
    display: none;
  }
}

@media (min-width: 500px) and (max-width: 1200px) {
  .testimonial-grey {
    width: 95%;
    height: 70vh;
  }
}

@media (min-width: 1200px) and (max-width: 1389px) {
  .testimonial-grey {
    width: 80%;
    height: 75vh;
  }
}

@media (max-width: 340px) {
  html,
  body {
    overflow: auto;
    /* Change from "hidden" to "auto" */
    max-height: 340px;
    /* Set the maximum height for scrolling */
  }

  .back-banner-2 {
    max-width: 100%;
  }

  .logos-slider-section {
    margin-top: 30vh;
    position: relative;
  }
}

@media (max-width: 550px) {
  .image-banner,
  .img-bg {
    padding-top: 40px;
    position: relative;
  }

  .front-h3 {
    font-size: 30vw;
    padding-top: 4%;
  }

  .paragraph-banner {
    margin: auto 10px;
  }

  .testimonial-grey {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .paragraph-banner {
    margin: 0;
  }

  .heading-banner {
    margin: 0;
    padding-bottom: 10px;
  }

  .heading-banner h2 {
    font-size: 2em;
    text-align: left;
  }

  .paragraph-banner {
    text-align: left;
  }
}

@media (max-width: 770px) {
  .social-icon-banner {
    justify-content: center;
    /* Center horizontally */
  }

  .clients-logos-carousal {
    aspect-ratio: 2/1 !important;
    object-fit: contain;
    mix-blend-mode: color-burn;
  }
}

@media (max-width: 768px) {
  .paragraph-banner h4 {
    font-size: 1.5rem;
    /*padding: 5px;*/
  }
}

@media (max-width: 768px) {
  .back-banner-2 {
    width: 100%;
    min-width: auto;
  }

  .data-banner {
    margin: 20px;
  }

  .back-banner-1 {
    height: auto;
  }

  .back-banner-2 {
    height: auto;
  }

  .back-banner-3 {
    height: auto;
  }
}

@media (max-width: 740px) {
  nav.active {
    right: 0;
    width: 100%;

    transition: right 1s ease;
    height: 100%;

    z-index: 1;
  }

  .container {
    margin-top: 20%;
  }
}

@media (max-width: 992px) {
  /*applies to under 992*/
  html {
    font-size: 55%;
  }

  .display-last {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .testimonials-logos {
    width: 100%;
  }

  .testimonials-written {
    width: 86%;
  }

  li,
  ul,
  a,
  button {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #edf0f1;
    text-decoration: none;
  }

  .logo {
    cursor: pointer;
    margin: auto;

    /*To put logo at left*/
  }

  .testimonial-grey {
    height: auto;
  }

  header {
    display: flex;
    /*justify-content: space-between;*/
    justify-content: flex-end;
    /*flex everything to right*/
    align-items: center;
    padding: 10px 5%;
  }

  nav {
    position: fixed;
    top: 0;
    right: -100%;
    width: 60%;
    height: 100vh;
    background: #1c1c1c;
    color: rgb(100, 100, 100);
    transition: right 1s ease;
    /* Added transition property */
  }

  /**/

  .nav__links_wildcard a {
    display: block;
    margin: 1rem;
    padding: 1rem;
    color: white;
    font-size: 1.5rem;
  }

  .nav__links_wildcard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: flex-start; */
    /* Center items vertically */
    position: absolute;
    /* Position within the container */
    transform: translate(-30%, -120%);
    /* Adjust for centering */
    top: 80%;
    /* Center vertically */
    left: 10%;
    /* Center horizontally */
  }

  .contactButton {
    display: none;
  }

  .back-banner-1 {
    max-height: 60vh;
  }

  .back-banner-1 {
    max-height: 60vh;
  }

  .little-lower {
    height: auto;
  }
}

@media (max-width: 900px) {
  .next-banner-1,
  .next-banner-2,
  .next-banner-3,
  .left-banner,
  .bg-solid-color,
  .right-banner {
    width: 100%;
    justify-content: center;
  }

  .bg-solid-color {
    height: 50vh;
    width: 40vh;
  }

  .text-change-area {
    height: 15vh;
  }
}

@media (min-width: 750px) {
  .slick-list {
    width: 100%;
  }

  .testimonials-logos {
    /* width: 50%; */
    /* width: auto; */
    height: auto;
    display: grid;
  }

  .testimonials-written {
    margin-bottom: 10px;
    min-height: 20vh;
  }
}

@media (max-width: 750px) {
  .bg-solid-color {
    height: 30vh;
    /*width: 40vh;*/
  }

  .right-banner {
    width: 90%;
  }

  .testimonials-written {
    width: 90%;
  }

  .testimonials-logos {
    margin-top: 1%;
    margin-bottom: 2rem;
  }

  .testimonial-clients {
    filter: grayscale(0%);
  }

  .tbl-col {
    width: 95% !important;
    flex-direction: column;
  }

  .left50 {
    width: 100% !important;
  }

  .tbl {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .testimonial-for-logos {
    width: 90% !important;
  }
}

@media (min-width: 550px) {
  .testimonial-for-logos {
    width: 85% !important;
    left: 0;
    top: 0;
  }
}

@media (max-width: 480px) {
  .text-change-area-h3 {
    font-size: 36px;
  }

  .description-who-we-are {
    font-size: 14px;
    padding: 20px;
  }
}

@media (max-width: 900px) {
  .next-banner-3,
  .next-banner-2,
  .next-banner-1 {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}

@media (max-width: 700px) {
  footer {
    bottom: unset;
  }

  .colFooter {
    flex-basis: 100%;
  }

  .colFooter:nth-child(2),
  .colFooter:nth-child(3) {
    flex-basis: 100%;
  }
}

.testimonial {
  margin: 0 24px;
  /*margin: 40px;*/
  font-family: "Montserrat", sans-serif;
  font-size: 13px;

  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.author {
  padding-top: 30px;
  font-weight: bold;
  font-size: 15px;
}

.designation {
  font-size: 11px;
}

.testimonial-for-logos {
  width: 100%;
}

.PositionRelative {
  position: relative;
}

.testimonial-groups:first-child {
  display: block;
}

.testimonial-groups {
  display: none;
}

.testimonial-clients {
  position: relative;
  width: 33.33%;
  height: 135px;
}

.testimonial-clientsImg {
  position: relative;
  top: 25%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 70%;
  max-height: 70%;
}

.testimonial-groups img {
  border-width: 0;
  display: block;
  outline: none;
  outline: 0;
  max-width: 100%;
  height: auto;
}

.testimonial-groups > a {
  cursor: pointer;
  text-decoration: none;
  text-transform: inherit;
  border-width: 0;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inherit;
}

.tbl {
  display: flex;
  /*background-color: red;*/
  margin-top: 5%;

  width: 100%;
  min-height: 80vh;

  justify-content: center;
  align-items: center;
}

.tbl-col {
  /*background-color: #39ac31;*/
  display: inline-flex;
  width: 70%;
  height: auto;
}

.left50 {
  width: 35%;
  /*background-color: #8fd79b;*/
}

.right50 {
  width: 50%;
  /*background-color: #d77777;*/
}

.services {
  padding-top: 54px;
  font-family: Bold, sans-serif;
  font-size: 20px;
  letter-spacing: 0.4em;
  max-width: 360px;
  padding-bottom: 74px;
}

.extra {
  padding: 0;
}

.margin7perc {
  margin-top: 7%;
  margin-bottom: 5%;
}

/* .hyper-link{
padding: 2rem;

} */

.SpecialLink {
  position: relative;
  font-family: "Bold", sans-serif;
  font-size: 11px;
  letter-spacing: 0.2em;
  color: #b42a76;
  padding-left: 20px;
  text-transform: uppercase;
  background-image: url(/public/img/images2/arrowpink.png);
  background-repeat: no-repeat;
  background-position: left center;
  display: inline-block;
}

.IWantAMouseOVerNowBut50,
.IWantAMouseOVerNowBut75,
.IWantAMouseOVerNowBut80,
.IWantAMouseOVerNowBut85,
.IWantAMouseOVerNow {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.SpecialLink a {
  cursor: pointer;
  text-decoration: none;
  text-transform: inherit;
  border-width: 0;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inherit;
}

.SpecialLink .Line {
  position: absolute;
  left: 20px;
  right: 0;
  bottom: -5px;
  height: 1px;
  background-color: #b42a76;
}

.more-about-us {
  height: 100vh;
}

.blobSvg1 {
  right: 0;
  width: 100%;
}

.blob1 {
  width: 100%;

  right: 0;
}

.grid {
  display: grid;
  /* grid-template-columns: repeat(3, minmax(150px, 1fr)); */
  /* grid-template-rows: repeat(3, minmax(100px, 1fr)) ; */
  /* grid-gap: 1rem; */
}

.logoGrid {
  background: transparent;
  padding: 1rem;
  display: grid;
}

.logoGrid::before,
.logoGrid > img {
  grid-area: 1 / 1 / 2 / 2;
}

.logoGrid > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper {
  width: 100%;
  height: 100%;
  /* margin-left: auto;
  margin-right: auto; */
}

.swiper-slide {
  height: auto;
}

.zindex0 {
  z-index: 0;
}

#menu-bar992 {
  color: #ffffff;
  font-size: 2em;
}

#menu-bar {
  color: #ffffff;
  font-size: 1em;
}

.menubar992 {
  width: 100%;
  /* height: 30px; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 12px;
}

#submit:hover {
  color: rgb(255, 255, 255);
}

.call-on-phone-number {
  color: #222222;
}

/* .swiper-pagination-bullets {
  display: none;
} */
.clients-logos-carousal {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}
